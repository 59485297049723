<template>
	<v-container fluid>

		<Loader v-if="pending" />

		<h3 v-else-if="files.length == 0" class="text-center text-h5" v-t="'descargas.vacio'" ></h3>

		<template v-else>

			<!-- <v-card class="pa-1 mx-auto mb-4 " max-width="800" > -->
			<v-row>
				<v-col cols="12" md="4" :offset-md="comer.length > 1 ? 2 : 4" sm="6" :offset-sm="comer.length > 1 ? 0 : 3">
					<v-text-field :placeholder="$t('common.busqueda')" solo hide-details v-model="filtro.titulo">
						<template v-slot:prepend-inner>
							<v-icon>mdi-magnify</v-icon>
						</template>
					</v-text-field>
				</v-col>
				<v-col v-if="comer.length > 1" cols="12" md="4" sm="6">
					<v-select hide-details solo clearable :placeholder="`[${$t('descargas.comers')}]`" :items="comer"
						v-model="filtro.comer"></v-select>
				</v-col>
				<v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2">
					<div class="d-flex flex-wrap align-baseline justify-center" style="gap: 10px">
						<v-card @click.stop="download(f)" :key="f.ref" v-for="f in filesFiltered" width="100%" max-width="688"
							class="pt-2">
							<!-- <v-img contain :src="`${$store.getters.getTenant.intranet}/imagenes/${f.imagen}`" height="100px"></v-img> -->

							<v-card-title style="word-break: normal; display: block;">
								<img class="pr-2" :src="`${$store.getters.getTenant.intranet}/imagenes/${f.imagen}`" height="30px" />
								<span class="pr-2">{{ f.titulo }} </span>
								<!-- <div class="d-flex flex-wrap px-4" style="gap: 6px"> -->
								<v-chip v-if="comer.length > 1 && f.comercializadora" label small>{{ f.comercializadora }}</v-chip>
								<!-- </div> -->
								<v-icon color="primary" class="float-rigth">mdi-download</v-icon>
							</v-card-title>

							<v-card-subtitle>
								{{ f.descripcion }}
							</v-card-subtitle>


							<!-- <v-chip label small>{{ f.tarifa ? f.tarifa : 'TODAS LAS TARIFAS' }}</v-chip> -->

							<!-- <v-card-actions>
						<v-btn block color="primary" text @click.stop="download(f)">
							<v-icon left>mdi-download</v-icon>
							Descargar
						</v-btn>
					</v-card-actions> -->

						</v-card>
					</div>
				</v-col>
			</v-row>
			<!-- </v-card> -->



		</template>

	</v-container>
</template>

<script setup>
import { perColumnFilter, downloadFile } from "@/utils/index.js";
import axios from '@/utils/axiosWrapper'
import { computed, getCurrentInstance, reactive } from "vue";
import Loader from '@/components/Loader.vue'

const root = getCurrentInstance().proxy.$root
const i18n = getCurrentInstance().proxy.$i18n

const { data: files, refresh, pending } = axios({
	method: 'GET',
	url: 'zona_descargas',
	default: () => ([])
})

const comer = computed(() => ([...new Set(files.value.map(f => f.comercializadora))]))

const filtro = reactive({
	titulo: '',
	comer: null,
})

const filesFiltered = computed(() => files.value.filter(f =>
	f.titulo.toLowerCase().includes(filtro.titulo.toLowerCase()) && (filtro.comer ? f.comercializadora == filtro.comer : true)
))



async function download({ ref, archivo, ruta }) {
	const { data, error, refresh } = axios({
		url: `zona_descargas/${ref}`,
		responseType: 'blob',
		block: true,
		lazy : true,
	})
	await refresh();

	if ( error.value ) {
		root.$emit('snack', i18n.t('documentacion.not_found'), { color: 'error' })
		return
	}

	const ext = ruta.split('.')[ ruta.split('.').length - 1 ]

	const file = new File([data.value], `${archivo}.${ext}`)

	downloadFile(file)
}

</script>